import { PageId } from '../constants';
import { BOOKINGS_APP_DEF_ID } from '@wix/bookings-adapters-reporting/dist/src/fedops/fedops-adapter';

export function addBookingsPage(pageId, editorSDK, appToken) {
  return editorSDK.document.tpa.add.component(appToken, {
    componentType: editorSDK.document.tpa.TPAComponentType.Page,
    page: {
      pageId,
      shouldNavigate: false,
    },
  });
}

function addStyledBookingsPage(pageId, style, editorSDK, appToken) {
  return editorSDK.document.tpa.add.component(appToken, {
    componentType: editorSDK.document.tpa.TPAComponentType.Page,
    copyStyle: true,
    styleId: style.id,
    page: {
      pageId,
      isHidden: true,
    },
  });
}

export const addBookingCheckoutPage = async (editorSDK, appToken) => {
  const allSitePages = await editorSDK.pages.data.getAll();
  const bookCheckoutPageData = allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_CHECKOUT,
  );
  if (!bookCheckoutPageData) {
    return editorSDK.document.tpa.add.component(appToken, {
      managingAppDefId: BOOKINGS_APP_DEF_ID,
      componentType: 'PAGE',
      appDefinitionId: BOOKINGS_APP_DEF_ID,
      page: {
        pageId: PageId.BOOKINGS_CHECKOUT,
        isHidden: false,
        shouldNavigate: false,
      },
    });
  }
};
export function addBookCheckoutPage(
  editorSDK,
  appDefinitionId,
  appToken,
  originStyle,
) {
  return addStyledBookingsPage(
    PageId.BOOKINGS_CHECKOUT,
    originStyle,
    editorSDK,
    appToken,
  );
}
